@font-face {
  src: url('./Assets/fonts/Poppins-Light.ttf');
  font-family: Poppins-Light;
}

@font-face {
  src: url('./Assets/fonts/Poppins-Medium.ttf');
  font-family: Poppins-Medium;
}
html {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #252525;
  font-size: 12px !important;
}

body {
  padding-top: 20px;
  font-family: 'Poppins', sans-serif !important;
  color: #fff;
  background-color: #252525;
  font-size: 12px !important;
  background-color: #252525 !important;
}
.container-fluid {
  background-color: #252525;
}
.justify-content-around {
  -webkit-justify-content: space-around!important;
  -ms-flex-pack: distribute!important;
  justify-content: space-around!important;
  margin-bottom: 27px
}

.flex-wrap {
  -webkit-flex-wrap: wrap!important;
  -ms-flex-wrap: wrap!important;
  flex-wrap: wrap!important;
}
.counter_single {
  text-align: center;
}
.counter_single .icon {
  padding-bottom: 2.33rem;
}
.counter_single .value {
  font-size: 3.2rem;
  line-height: 3.66rem;
  font-weight: 600;
  color: #ea750d;
  margin-bottom: 0;
}

.counter_single p {
  margin: 0;
}
.counter_single .title {
  color: #ea750d;
}

.counter_single p {
  margin: 0;
}
p {
  line-height: 2rem;
}
.h4, h4 {
  font-size: 1.75rem;
  color: #ea750d;
}
.h3, h3 {
  font-size: 1.75rem;
  color: #ea750d;
}
.h6, h5 {
  color: #fff !important;
}
.card {
  border: 1px solid rgba(0,0,0,.125) !important;
  margin-top: 30px !important;
  transform: translateZ(0);
  box-shadow: 0 0 10px 0 rgba(19, 18, 18, 0.26);
}
.card-body {
  background: #1d1c1c;
  border: 1px solid #8d141420 !important;
  max-height: 505px;
  overflow-y: scroll;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #989a9c !important;
}
.text-center {
  background-color: #1d1c1c;
  text-align: center!important;
}

.carousel-caption {
  background: #0808088f !important;
}
.footer-s{
  color: rgba(255,255,255,.6);
  margin: 0;
  padding: 30px 0;
  font-size: 14px;
}

@media (min-width: 1200px){
.container {
    max-width: 1140px;
}}
@media (min-width: 992px){
.container {
    max-width: 960px;
}}
@media (min-width: 768px){
.container {
    max-width: 720px;
}}
@media (min-width: 576px){
.container {
    max-width: 540px;
}}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #252525;
}
/* .scroll-style
{
    max-height: 505px;
    overflow-y: scroll;
} */
.table-hover tbody tr:hover {
  color: #212529 !important;
  background-color: #ea750d !important;
}
.carousel-1 {
  background-image: url("./Assets/images/lotto.jpg");
  background-repeat: cover;
}
.carousel-2 {
  background-image: url("./Assets/images/blockchain.png");
  background-repeat: cover;
}
.carousel-3 {
  background-image: url("./Assets/images/lotto2.jpg");
  background-repeat: cover;
}

