
.justify-content-around {
  -webkit-justify-content: space-around!important;
  -ms-flex-pack: distribute!important;
  justify-content: space-around!important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap!important;
  -ms-flex-wrap: wrap!important;
  flex-wrap: wrap!important;
}